import { CodeSurfer, CodeSurferColumns, Step } from "code-surfer";
import { github, vsDark } from "@code-surfer/themes";
import { Pokeball } from "../../../../decks/components/pokeball";
import * as React from 'react';
export default {
  CodeSurfer,
  CodeSurferColumns,
  Step,
  github,
  vsDark,
  Pokeball,
  React
};